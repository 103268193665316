import React from "react";
import { Routes, Route } from "react-router-dom";
import About from "../../components/about/About";
import Contact from "../contact/Contact";
import Custom from "../Customize/Custom";
import Home from "../home/Home";
import Menu from "../menu/Menu";

function Routs() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/Custom" element={<Custom />} />
      </Routes>
    </div>
  );
}

export default Routs;
