import React from 'react'
import "./Menu.css"
import spider from "../../assets/spider.png"
import frozen from "../../assets/frozen.png"
import cake from "../../assets/2.jpg"
import pic1 from "../../assets/1.jpg"
import pic2 from "../../assets/2.jpg"
import pic3 from "../../assets/3.jpg"
import pic4 from "../../assets/4.jpg"
import pic5 from "../../assets/5.jpg"
import pic6 from "../../assets/6.jpg"
import pic7 from "../../assets/7.jpg"
import pic8 from "../../assets/8.jpg"
import pic9 from "../../assets/9.jpg"
import pic10 from "../../assets/10.jpg"
import pic11 from "../../assets/11.jpg"
import pic12 from "../../assets/12.jpg"
import pic13 from "../../assets/13.jpg"
import pic14 from "../../assets/14.jpg"
import pic15 from "../../assets/15.jpg"
import pic16 from "../../assets/16.jpg"
import pic17 from "../../assets/17.jpg"
import pic18 from "../../assets/18.jpg"
import pic19 from "../../assets/19.jpg"
import pic20 from "../../assets/20.jpg"
import pic21 from "../../assets/21.jpg"
import pic22 from "../../assets/22.jpg"
import pic23 from "../../assets/23.jpg"
import pic24 from "../../assets/24.jpg"
import pic27 from "../../assets/27.png"
import pic28 from "../../assets/28.png"
import pic29 from "../../assets/29.png"
import pic30 from "../../assets/logo1.png"
import pic31 from "../../assets/31.png"
import pic32 from "../../assets/32.png"

function Menu() {
  return (
    <div className='container-menu'>
      <div className='menuimage'>
        <h1 className='mainmenu'>Menu</h1>
      </div>

      <div className='row-spider mt-5'>
        <div className='spider'>
          <img className='imgspider' src={spider} />
        </div>
        <div className='edible'>
          <h3 className='edibletext'>Edible Images</h3>
          <div className='div-forzon mt-5'>
            <img className='frozen-img' src={frozen} />
            <h5 className='hifrozon mt-3'>Edible image-Frozen</h5>
          </div>
          <div className='div-forzon mt-3'>
            <img className='frozen-img' src={spider} />
            <h5 className='hifrozon mt-3'>Edible image-Spider</h5>
          </div>
        </div>
      </div>
<br />
<div className='row-spider mt-5'>
        <div className='spider'>
          <img className='imgspider' src={pic27} />
        </div>
        <br />
        <div className='edible'>
          <h3 className='edibletext'>Custom Cakes</h3>
          <div className='div-custom mt-5'>
            <img className='custom-img' src={pic28} />
            <div>
            <h5 className='hicustom'>Custom cakes</h5>
            <p className='pcustom'>10” Square Slab / 12” Square Slab</p>
            <p className='pcustom'>14” Square Slab / 16” Square Slab</p>
            <p className='pcustom'>Also Available on Pre Order</p>
          </div>
          </div>
         
        </div>
      </div>
      <br />

        <div className='row-spider mt-5'>
          <div className='spider'>
            <div className='main-tortesimage'>
            <img className='imgspider' src={pic24} />
            <img className='imgspider' src={pic1} />
            <img className='imgspider' src={pic2} />
            <img className='imgspider' src={pic6} />
            <img className='imgspider' src={pic3} />
            <img className='imgspider' src={pic4} />
          </div>
          
          </div>
          <br />
          <div className='edible'>
            <h3 className='edibletext'>Tortes & Gateaux</h3>
            <div className='div-custom mt-5'>
              <div className='col-5 one'>
              <img className='custom-img' src={pic30} />
              </div>
              <div className='col-5 two mt-3'>
              <h5 className='hicustom'>Strawberry Gateau </h5>
              <p className='pcustom'>Full $35.95  Half $20</p>
            </div>
            </div>

            <div className='div-custom mt-5'>
              <div  className='col-5 one'>
              <img className='custom-img' src={pic24} />
              </div>
              <div className='col-5 two mt-3'>
              <h5 className='hicustom'>Strawberry Cream Gateau </h5>
              <p className='pcustom'>Full $35.95  Half $20</p>
            </div>
            </div>

            <div className='div-custom mt-5'>
              <div  className='col-5 one'>
              <img className='custom-img' src={pic29} />
              </div>
              <div className='col-5 two mt-3'>
              <h5 className='hicustom'>Swiss Chocolate Gateau </h5>
              <p className='pcustom'>Full $35.95  Half $20</p>
            </div>
            </div>

            <div className='div-custom mt-5'>
              <div  className='col-5 one'>
              <img className='custom-img' src={pic1} />
              </div>
              <div className='col-5 two mt-3'>
              <h5 className='hicustom'>French Custard Gateau</h5>
              <p className='pcustom'>Full $35.95  Half $20</p>
            </div>
            </div>

            <div className='div-custom mt-5'>
              <div  className='col-5 one'>
              <img className='custom-img' src={pic2} />
              </div>
              <div className='col-5 two mt-3'>
              <h5 className='hicustom'>Nutella Cream Torte</h5>
              <p className='pcustom'>Full $35.95  Half $20</p>
            </div>
            </div>

            <div className='div-custom mt-5'>
              <div  className='col-5 one'>
              <img className='custom-img' src={pic3} />
              </div>
              <div className='col-5 two mt-3'>
              <h5 className='hicustom'>Bee Sting Gateau </h5>
              <p className='pcustom'>Full $35.95  Half $20</p>
            </div>
            </div>

            <div className='div-custom mt-5'>
              <div  className='col-5 one'>
              <img className='custom-img' src={pic4} />
              </div>
              <div className='col-5 two mt-3'>
              <h5 className='hicustom'>Coffee Hazelnut Gateau </h5>
              <p className='pcustom'>Full $35.95  Half $20</p>
            </div>
            </div>

            <div className='div-custom mt-5'>
              <div  className='col-5 one'>
              <img className='custom-img' src={pic5} />
              </div>
              <div className='col-5 two mt-3'>
              <h5 className='hicustom'>Caramel Cream Gateau </h5>
              <p className='pcustom'>Full $35.95  Half $20</p>
            </div>
            </div>

            <div className='div-custom mt-5'>
              <div  className='col-5 one'>
              <img className='custom-img' src={pic6} />
              </div>
              <div className='col-5 two mt-3'>
              <h5 className='hicustom'>Black Forest Gateau</h5>
              <p className='pcustom'>Full $35.95  Half $20</p>
            </div>
            </div>

            <div className='div-custom mt-5'>
              <div  className='col-5 one'>
              <img className='custom-img' src={pic30} />
              </div>
              <div className='col-5 two mt-3'>
              <h5 className='hicustom'>French Tropical Gateau</h5>
              <p className='pcustom'>Full $35.95  Half $20</p>
            </div>
            </div>

            <div className='div-custom mt-5'>
              <div  className='col-5 one'>
              <img className='custom-img' src={pic30} />
              </div>
              <div className='col-5 two mt-3'>
              <h5 className='hicustom'>Continental Profiterole Gateau</h5>
              <p className='pcustom'>Full $35.95  Half $20</p>
            </div>
            </div>

            <div className='div-custom mt-5'>
              <div  className='col-5 one'>
              <img className='custom-img' src={pic30} />
              </div>
              <div className='col-5 two mt-3'>
              <h5 className='hicustom'>Wilberry Fantasy Torte</h5>
              <p className='pcustom'>Full $35.95  Half $20</p>
            </div>
            </div>

            <div className='div-custom mt-5'>
              <div  className='col-5 one'>
              <img className='custom-img' src={pic30} />
              </div>
              <div className='col-5 two mt-3'>
              <h5 className='hicustom'>Quarters </h5>
              <p className='pcustom'>$9.95</p>
            </div>
            </div>
          </div>
        </div>
        <br />

        <div className='row-spider mt-5'>
        <div className='spider'>
          <div className='main-tortesimage'>
          <img className='imgspider' src={pic8} />
          <img className='imgspider' src={pic9} />
          <img className='imgspider' src={pic11} />
     
        </div>
        
        </div>
        <br />
        <div className='edible'>
          <h3 className='edibletext'>Baked Cheese Cakes</h3>
          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic7} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Baked Newyork</h5>
            <p className='pcustom'>Full $29.95  Half $17</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic8} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Baked Chocolate </h5>
            <p className='pcustom'>Full $29.95  Half $17</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic9} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Baked Choc Vanilla </h5>
            <p className='pcustom'>Full $29.95  Half $17</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic10} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Baked Texas Chocolate</h5>
            <p className='pcustom'>Full $29.95  Half $17</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic11} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Baked Blueberry</h5>
            <p className='pcustom'>Full $29.95  Half $17</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic30} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Baked Mix Berry</h5>
            <p className='pcustom'>Full $29.95  Half $17</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic30} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Quarters </h5>
            <p className='pcustom'>$8.95</p>
          </div>
          </div>
        </div>
      </div>
      <br />

      <div className='row-spider mt-5'>
        <div className='spider'>
          <div className='main-tortesimage'>
          <img className='imgspider' src={pic14} />
          <img className='imgspider' src={pic12} />
          <img className='imgspider' src={pic13} />
          <img className='imgspider' src={pic16} />
     
        </div>
        
        </div>
        <br />
        <div className='edible'>
          <h3 className='edibletext'>Continental Cheesecakes</h3>
          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic12} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Continental Lemon</h5>
            <p className='pcustom'>Full $29.95  Half $17</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic13} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Continental Caramel</h5>
            <p className='pcustom'>Full $29.95  Half $17</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic14} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Continental Choc Vanilla </h5>
            <p className='pcustom'>Full $29.95  Half $17</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic15} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Continental Vanilla</h5>
            <p className='pcustom'>Full $29.95  Half $17</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic16} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Continental Blueberry</h5>
            <p className='pcustom'>Full $29.95  Half $17</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic17} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Continental Passionfruit</h5>
            <p className='pcustom'>Full $29.95  Half $17</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic18} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Continental Strawberry</h5>
            <p className='pcustom'>Full $29.95  Half $17</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic19} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Continental Cookies & Cream</h5>
            <p className='pcustom'>Full $29.95  Half $17</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic30} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Continental Mango</h5>
            <p className='pcustom'>Full $29.95  Half $17</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic30} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Quarters </h5>
            <p className='pcustom'>$8.95</p>
          </div>
          </div>
        </div>
      </div>
      <br />

      <div className='row-spider mt-5'>
        <div className='spider'>
          <div className='main-tortesimage'>
          <img className='imgspider' src={pic21} />
          <img className='imgspider' src={pic23} />
          <img className='imgspider' src={pic22} />
     
        </div>
        
        </div>
        <br />
        <div className='edible'>
          <h3 className='edibletext'>Mud Cakes</h3>
          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic20} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Chocolate Mudcake</h5>
            <p className='pcustom'>Full $35.95  Half $20</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic21} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Chocolate & Vanilla Mudcake </h5>
            <p className='pcustom'>Full $35.95  Half $20</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic22} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>White Choc Mudcake </h5>
            <p className='pcustom'>Full $35.95  Half $20</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic23} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Caramel Mudcake</h5>
            <p className='pcustom'>Full $35.95  Half $20</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic30} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Banana Mudcake</h5>
            <p className='pcustom'>Full $35.95  Half $20</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic30} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Strawberry Mudcake</h5>
            <p className='pcustom'>Full $35.95  Half $20</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic30} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Quarters & Slices </h5>
            <p className='pcustom'>Slice $3.95  Quarter $9.95</p>
          </div>
          </div>
        </div>
      </div>
      <br />

      <div className='row-spider mt-5'>
        <div className='spider'>
          <div className='main-tortesimage'>
          <img className='imgspider' src={pic31} />
          <img className='imgspider' src={pic32} />
          <img className='imgspider' src={pic28} />     
        </div>
        
        </div>
        <br />
        <div className='edible'>
          <h3 className='edibletext'>Specialty Cakes</h3>
          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic30} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Orange Poppyseed Cake</h5>
            <p className='pcustom'>Full $29.95  Half $17</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic30} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Carrot Cake</h5>
            <p className='pcustom'>Full $29.95  Half $17</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic30} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Pavlova </h5>
            <p className='pcustom'>Full $29.95  Half $17</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic30} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Lemon Meringue</h5>
            <p className='pcustom'>Full $29.95  Half $17</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic30} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>French Pecan Pie</h5>
            <p className='pcustom'>Full $29.95  Half $17</p>
          </div>
          </div>

          <div className='div-custom mt-5'>
            <div  className='col-5 one'>
            <img className='custom-img' src={pic30} />
            </div>
            <div className='col-5 two mt-3'>
            <h5 className='hicustom'>Rainbow Cake</h5>
            <p className='pcustom'>Full $60</p>
          </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  )
}

export default Menu