
import Footer from './components/footer/Footer';
import './App.css';
import { HashRouter } from 'react-router-dom';
import Routs from './components/routes/Routs';
import Header from './components/header/Header';
function App() {
  return (
    <>
    <HashRouter >
    <Header />
      <Routs />
      <Footer />
      </HashRouter>
    </>
  );
}

export default App;
