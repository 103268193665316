import React from "react";
import "../Customize/Custom.css";
import Mainlogo from "../../assets/mainlogo.png";
import { useNavigate } from "react-router-dom";
import cake25 from '../../assets/51.png'
import cake2 from '../../assets/28.png'
import cake3 from '../../assets/11.jpg'
import cake4 from '../../assets/18.jpg'
import cake5 from '../../assets/23.jpg'
import cake6 from '../../assets/61.png'
import cake7 from '../../assets/34.png'
import cake8 from '../../assets/1.jpg'
import cake9 from '../../assets/64.png'
import cake10 from '../../assets/11.jpg'
import cake12 from '../../assets/57.png'
import cake13 from '../../assets/58.png'
import cake14 from '../../assets/60.png'
import cake15 from '../../assets/62.png'
import cake16 from '../../assets/63.png'
import cake17 from '../../assets/65.png'
import cake18 from '../../assets/59.png'
import cake19 from '../../assets/54.png'
import cake20 from '../../assets/52.png'
import cake21 from '../../assets/53.png'
import cake22 from '../../assets/56.png'
import cake23 from '../../assets/55.png'
import cake66 from '../../assets/66.png'
import cake67 from '../../assets/67.png'
import cake68 from '../../assets/68.png'
import cake69 from '../../assets/69.png'
import cake70 from '../../assets/70.png'
import cake71 from '../../assets/71.png'
import cake72 from '../../assets/72.png'
import cake73 from '../../assets/73.png'
import cake74 from '../../assets/74.png'
import cake75 from '../../assets/75.png'
import cake76 from '../../assets/76.png'
import cake77 from '../../assets/77.png'
import cake78 from '../../assets/78.png'
import cake79 from '../../assets/79.png'
import cake80 from '../../assets/80.png'
import cake81 from '../../assets/81.png'
import cake82 from '../../assets/82.png'
import cake83 from '../../assets/83.png'
import cake84 from '../../assets/84.png'
import cake85 from '../../assets/85.png'
import cake86 from '../../assets/86.png'
import cake87 from '../../assets/87.png'
import cake88 from '../../assets/88.png'
import cake89 from '../../assets/89.png'
import cake90 from '../../assets/90.png'

export default function Custom() {
    let navigate=useNavigate();
  return  (
    <div className="text-center ">
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <h1>Customized Cakes</h1>
      <p className="text33"><b>You design. We Bake.</b></p>
      <img src={Mainlogo} className="img img-fluid customs_logo" />
      <br></br>
      <br></br>
      <p>
        Collection by <b className="text33">THE CAKE HUT</b>
      </p>
      <h4 className="text33">Similar ideas popular now</h4>
      <div className="cakes">
        <button type="button"className="button ms-3 " onClick={()=>navigate("/menu")}><img className='L' src={cake4} /><b></b></button> 
        <button type="button"className="button ms-3 " onClick={()=>navigate("/menu")} ><img className='L' src={cake2} /><b></b></button>
        <button type="button"className="button ms-3 " onClick={()=>navigate("/menu")} ><img className='L' src={cake5} /><b></b></button>
        <button type="button"className="button ms-3 " onClick={()=>navigate("/menu")}><img className='L' src={cake7} /><b></b></button>
        <button type="button"className="button ms-3 " onClick={()=>navigate("/menu")} ><img className='L' src={cake10} /><b></b></button>
      </div>
      
      
            <div className='container-fluid  bg_cakes1 pt-3'>
                <div className='top-maincontainer mt-5'>
            <div className='container-hometop'>
            <div className='row-hometop'>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                    <img className='topimage2' src={cake25} />
                    </div>
                    
                    <div className="A">
                        {/* <button type="button" className="buttons ms-1 mt-1" onClick={()=>navigate("/")} >Cake</button>
                        <button type="button" className="buttons ms-1 mt-1" onClick={()=>navigate("/")} >Design</button>
                        <button type="button" className="buttons ms-1 mt-1" onClick={()=>navigate("/")}>Birthday</button>
                        <button type="button" className="buttons ms-1 mt-1" onClick={()=>navigate("/")} >Birthday Cake</button> */}
                         </div>
                       {/* <h5 className='text ' >Free Writing on Cakes</h5> */}
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake20} />
                    </div>
                    <div className="A">
                        {/* */}
                         </div>
                    {/* <h5 className='text  ' >Cube Cake</h5> */}
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake21} />
                    </div>
                    <div className="A">
                       
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake19} />
                    </div>
                    <div className="A">
                       
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake23} />
                    </div>
                    <div className="A">
                       
                         </div>
                </div>
            </div>

            </div>
            </div>
            </div>
            <div className='container-fluid  bg_cakes1 pt-3'>
                <div className='top-maincontainer mt-4'>
            <div className='container-hometop'>
            <div className='row-hometop'>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                    <img className='topimage2' src={cake22} />
                    </div>
                    
                    <div className="A">
                     
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake12} />
                    </div>
                    <div className="A">
                       
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake13} />
                    </div>
                    <div className="A">
                       
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake18} />
                    </div>
                    <div className="A">
                       
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake14} />
                    </div>
                    <div className="A">
                       
                         </div>
                </div>
            </div>

            </div>
            </div>
            </div>
            <div className='container-fluid  bg_cakes1 pt-3'>
          
      <div className='row-homedown mt-4'>
            <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                    <img className='topimage2' src={cake6} />
                    </div>
                    <div className="A">
                     
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake15} />
                    </div>
                    <div className="A">
                      
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake16} />
                    </div>
                    <div className="A">
                        
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                <img className='topimage2 ' src={cake9} />
                    </div>
                    <div className="A">
                       
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake17} />
                    </div>
                    <div className="A ">
                       
                         </div>
                </div>
            </div>

            <div className='row-homedown mt-4'>
            <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                    <img className='topimage2' src={cake66} />
                    </div>
                    <div className="A">
                     
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake67} />
                    </div>
                    <div className="A">
                      
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake68} />
                    </div>
                    <div className="A">
                        
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                <img className='topimage2 ' src={cake69} />
                    </div>
                    <div className="A">
                       
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake70} />
                    </div>
                    <div className="A ">
                       
                         </div>
                </div>
            </div>

            <div className='row-homedown mt-4'>
            <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                    <img className='topimage2' src={cake71} />
                    </div>
                    <div className="A">
                     
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake72} />
                    </div>
                    <div className="A">
                      
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake73} />
                    </div>
                    <div className="A">
                        
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                <img className='topimage2 ' src={cake74} />
                    </div>
                    <div className="A">
                       
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake75} />
                    </div>
                    <div className="A ">
                       
                         </div>
                </div>
            </div>

            <div className='row-homedown mt-4'>
            <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                    <img className='topimage2' src={cake76} />
                    </div>
                    <div className="A">
                     
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake77} />
                    </div>
                    <div className="A">
                      
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake78} />
                    </div>
                    <div className="A">
                        
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                <img className='topimage2 ' src={cake79} />
                    </div>
                    <div className="A">
                       
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake80} />
                    </div>
                    <div className="A ">
                       
                         </div>
                </div>
            </div>

            <div className='row-homedown mt-4'>
            <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                    <img className='topimage2' src={cake81} />
                    </div>
                    <div className="A">
                     
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake82} />
                    </div>
                    <div className="A">
                      
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake83} />
                    </div>
                    <div className="A">
                        
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                <img className='topimage2 ' src={cake84} />
                    </div>
                    <div className="A">
                       
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake85} />
                    </div>
                    <div className="A ">
                       
                         </div>
                </div>
            </div>

            <div className='row-homedown mt-4'>
            <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                    <img className='topimage2' src={cake86} />
                    </div>
                    <div className="A">
                     
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake87} />
                    </div>
                    <div className="A">
                      
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake88} />
                    </div>
                    <div className="A">
                        
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                <img className='topimage2 ' src={cake89} />
                    </div>
                    <div className="A">
                       
                         </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>

                    <img className='topimage2' src={cake90} />
                    </div>
                    <div className="A ">
                       
                         </div>
                </div>
            </div>

            </div>  
    </div>  
  );
}
