import React from 'react'
import { Card } from 'react-bootstrap'
import '../cakecards/cakeCard.css'
import banner from '../../assets/banner.jpg'
import cake2 from '../../assets/28.png'
import cake3 from '../../assets/11.jpg'
import cake4 from '../../assets/18.jpg'
import cake5 from '../../assets/23.jpg'
import cake6 from '../../assets/33.png'
import cake7 from '../../assets/34.png'
import cake8 from '../../assets/1.jpg'
import cake9 from '../../assets/35.png'
import cake10 from '../../assets/36.png'
import cake11 from '../../assets/CakeHut_Hero.jpg'
import cake12 from '../../assets/11.jpg'
import cake13 from '../../assets/37.png'
import cake14 from '../../assets/38.png'
import cake15 from '../../assets/39.png'
import cake16 from '../../assets/40.png'
import cake17 from '../../assets/41.png'
import cake18 from '../../assets/23.jpg'
import cake19 from '../../assets/24.jpg'
import cake20 from '../../assets/42.png'
import cake21 from '../../assets/43.png'
import cake22 from '../../assets/28.png'
import cake23 from '../../assets/8.jpg'
import cake24 from '../../assets/11.jpg'
import cake25 from '../../assets/44.png'
import cake26 from '../../assets/36.png'
import spider from "../../assets/spider.png"



function CakeCard() {
    return (
        <div>
            <div className='container-fluid  bg_cakes1 pt-3'>
                <div className='top-maincontainer mt-5'>
            <div className='container-hometop'>
            <div className='row-hometop'>
                <div className='col-2-h hometop'>
                    
                    <div className='maintopimg'>
                    <img className='topimage' src={spider} />
                    <h5 className='texth5  mt-4' >Edible Images</h5>
                    </div>
                    </div>
                
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                <img className='topimage' src={cake2} />
                    <h5 className='texth5   mt-4' >Free Writing on Cakes</h5>
                    </div>
                </div>

                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                <img className='topimage' src={cake3} />
                    <h5 className='texth5  mt-4' >Baked Cheese Cakes</h5>
                    </div>
                </div>

                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                <img className='topimage' src={cake4} />
                    <h5 className='texth5  mt-4' >Continental Cheesecakes</h5>
                    </div>
                </div>

                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                <img className='topimage' src={cake5} />
                    <h5 className='texth5  mt-4' >Mud Cakes</h5>
                    </div>
                </div> 
            </div>
            <br />

            <div className='row-homedown mt-4'>
            <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                    <img className='topimage' src={cake6} />
                    <h5 className='texth5  mt-4' >Specialty Cakes</h5>
                    </div>
                </div>

                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                <img className='topimage' src={cake7} />
                    <h5 className='texth5  mt-4' >Custom Cakes</h5>
                    </div>
                </div>

                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                <img className='topimage' src={cake8} />
                    <h5 className='texth5  mt-4' >Tortes & Gateaux</h5>
                    </div>
                </div>

                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                <img className='topimage' src={cake9} />
                    <h5 className='texth5  mt-4' >Cup Cakes</h5>
                    </div>
                </div>

                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                <img className='topimage' src={cake10} />
                    <h5 className='texth5  mt-4' >Tiered Cakes</h5>
                    </div>
                </div>
            </div>
            </div>
            </div>

                <div className='row '>
                    <div className='col-12 p-0 '>
                        <img src={banner} className='balan' alt='banner' />
                    </div>
                </div>
                <br />

                <div className='row-homedown2 '>
            <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                    <img className='topimage2' src={cake11} />
                    </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                <img className='topimage2' src={cake12} />
                    </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                <img className='topimage2' src={cake13} />
                    </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                <img className='topimage2' src={cake14} />
                    </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                <img className='topimage2' src={cake15} />
                    </div>
                </div>
            </div>

            <div className='row-homedown2 mt-4'>
            <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                    <img className='topimage2' src={cake16} />
                    </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                <img className='topimage2' src={cake17} />
                    </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                <img className='topimage2' src={cake18} />
                    </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                <img className='topimage2' src={cake19} />
                    </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                <img className='topimage2' src={cake20} />
                    </div>
                </div>
            </div>

            <div className='row-homedown2 mt-4'>
            <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                    <img className='topimage2' src={cake21} />
                    </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                <img className='topimage2' src={cake22} />
                    </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                <img className='topimage2' src={cake23} />
                    </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                <img className='topimage2' src={cake24} />
                    </div>
                </div>
                <div className='col-2-h hometop'>
                    <div className='maintopimg'>
                <img className='topimage2' src={cake25} />
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            </div>
        </div>
    )
}

export default CakeCard