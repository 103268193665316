import React from 'react'
import Mainlogo from '../../assets/mainlogo.png'
import '../footer/Footer.css'
import { HiOutlineMail } from "react-icons/hi";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

function Footer() {
    return (
        <div>
            <div className='container-fluid'>
                <div className='row p-5'>
                    <div className='col-sm-4 text-center mt-2'>
                        <img src={Mainlogo} className='img img-fluid custom_logo' />
                    </div>
                    <div className='col-sm-4 text-center'>
                        <h5 className='textfooter mt-4' type="button"><a href='tel:08 8123 2678' className='tel'>Tel: 08 8123 2678 </a></h5>
                        <p> Shop 2A - 985 South Road<br />

                            Melrose Park,  Adelaide, SA 5039</p>

                        <h6 className='textfooter'> Email:<b> info@thecakehut.com.au</b></h6>

                        <h5 className='textfooter mt-3'><b> Trading Hours</b></h5>

                        <h5 className='textfooter'><b>Monday to Saturday 10am till 8pm</b></h5>
                        <h5 className='textfooter'><b>Sunday 10am till 6pm</b></h5>
                    </div>
                    <div className='col-sm-4 text-center'>
                        <h5 className='textfooter mt-4'>Follow Us</h5>
                        <div className='footer-icon mt-4'>
                            <a
                                href="mailto:info@thecakehut.com.au"
                           
                                className="mail_float"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <HiOutlineMail className='footericon' />
                            </a>
                            <a
                                href="https://m.facebook.com/pages/category/Local-business/The-Cake-Hut-110241560396357/"
                                className="mail_float"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaFacebookF className='footericon ms-4' />
                            </a>
                            <a
                                href="https://www.instagram.com/thecakehut.com.au/?hl=en"
                                className="mail_float"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaInstagram className='footericon ms-4' />
                            </a>
                        </div>

                        <h5 className='textfooter2 mt-4'>We also offer free writing on cakes!</h5>

                        <p className='textfooter3 mt-3'>© 2022 All Rights Reserved | The Cake Hut</p>

                        <p className='textfoote'> Site powered by <b style={{ color: "#552727" }}>BrainsPK</b></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer