import React from 'react'
import "./Header.css"
import { useState, } from "react";
import { Container, Nav, Navbar, Button } from "react-bootstrap";
import logo from "../../assets/mainlogo.png"
import { HiOutlineMail } from "react-icons/hi";
import { AiOutlinePhone } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { HiOutlineMenu} from "react-icons/hi";
import {MdPhone} from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { Offcanvas } from "react-bootstrap";


function Header() {
  const navigate = useNavigate()
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);




  return (
    <div className='main-header1'>
      <div className='togglebutton'>
      <button className='sidebtn' onClick={handleShow}>
       <HiOutlineMenu />
      </button>

      <Offcanvas style={{width:"260px"}} className='sidemain' show={show} onHide={handleClose}>
        <Offcanvas.Header className='sidemain' closeButton>
          <Offcanvas.Title>
         
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='sidemain'>
        <div className="me-auto">
              <h3 className='sidetext ms-5' type="button" onClick={() => navigate("/")}>Home</h3 >
              <h3 className='sidetext ms-5' type="button" onClick={() => navigate("/menu")}>Menu</h3>
              <h3 className='sidetext ms-5' type="button" onClick={() => navigate("/about")}>About Us</h3>
              <h3 className='sidetext ms-5' type="button" onClick={() => navigate("/contact")} >Contact Us</h3>
              <h3 className='sidetext ms-5' type="button" onClick={()=>navigate("/custom")}>Custom Cake </h3>

            </div>
        </Offcanvas.Body>
        <button className='navbtn ms-4'><a href='tel:08 8123 2678' className='tel'>Tel: 08 8123 2678</a></button>
        <div className='nav-icon ms-5'>         
                <div className='iconss' >
                  <a
                    href="mailto:info@thecakehut.com.au"
                    className="mail_float"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <HiOutlineMail type="button" className='naviconm' />
                  </a>
                </div>
                <div className='iconss'>
                  <a
                    href="https://m.facebook.com/pages/category/Local-business/The-Cake-Hut-110241560396357/"
                    className="mail_float"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebookF type="button" className='naviconf' />
                  </a>
                </div>
                <div className='iconss'>
                  <a
                    href="https://www.instagram.com/thecakehut.com.au/?hl=en"
                    className="mail_float"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram type="button" className='navicon' />
                  </a>
                </div>
              </div>
              <br/>
      </Offcanvas>
    </div>
      <Navbar className='main-navbar' collapseOnSelect expand="lg">
        
        <Container className='nav-container'>
          <Navbar.Brand className='brandnav'>
            <img className='navlogo' src={logo} />
          </Navbar.Brand>
          
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className='togglebutton1' />
          <Navbar.Collapse id="responsive-navbar-nav">
            <br />
            <Nav className="me-auto">
              <h3 className='navtext ms-3' type="button" onClick={() => navigate("/")}>Home</h3 >
              <h3 className='navtext ms-3' type="button" onClick={() => navigate("/menu")}>Menu</h3>
              <h3 className='navtext ms-3' type="button" onClick={() => navigate("/about")}>About Us</h3>
              <h3 className='navtext ms-3' type="button" onClick={() => navigate("/contact")} >Contact Us</h3>
              <h3 className='navtext ms-3' type="button" onClick={()=>navigate("/Custom")}>Custom Cake </h3>
            </Nav>
            <br />
            <Nav className='navbtnss '>
              <button className='navbtn'><a href='tel:08 8123 2678' className='tel'>Tel: 08 8123 2678</a></button>
              <div className='nav-icon'>
                <div className='iconss' >
                  <a
                    href="mailto:info@thecakehut.com.au"
                    className="mail_float"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <HiOutlineMail type="button" className='naviconm' />
                  </a>
                </div>
                <div className='iconss'>
                  <a
                    href="https://m.facebook.com/pages/category/Local-business/The-Cake-Hut-110241560396357/"
                    className="mail_float"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebookF type="button" className='naviconf' />
                  </a>
                </div>
                <div className='iconss'>
                  <a
                    href="https://www.instagram.com/thecakehut.com.au/?hl=en"
                    className="mail_float"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram type="button" className='navicon' />
                  </a>
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
          <MdPhone className='phone-icon'/> 
        </Container>
      </Navbar>

    </div>
  )
}

export default Header